.splash-container {
  text-align: center;
  padding: 2rem;
}

.splash-image {
  max-width: 75%;
  height: 500px;
  margin-bottom: 0rem;
}

.splash-icons {
  max-width: 100%;
  display: flex;
  justify-content: center; /* Centers the icons */
  gap: 5rem; /* Adds space between the icons */
  margin: 2rem auto; /* Centers the entire container */
}

.icon-item {
  text-align: center;
}

.icon-item p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
}

.splash-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.splash-button:hover {
  background-color: #0056b3;
}